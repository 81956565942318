import { useLocation } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import { parse } from "query-string"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import ThankIcon from "../../assets/images/thank-icon.png"
import Frame from "../../components/common/frame"
import Navigation from "../../components/navigation"

function ThankYou() {
  const location = useLocation()
  const searchParams = parse(location.search)
  const [message, setMessage] = useState("")
  const subdomainCheck = /[.]/.test(searchParams.subdomain)
  const encodedToken = encodeURIComponent(searchParams.token)
  const allowedHosts = ["superops.com", "superops.ai", "superopsalpha.com"]

  const isAllowedHost = allowedHosts.includes(searchParams?.host)

  useEffect(() => {
    if (window.innerWidth > 992) {
      setMessage("We’re getting your dashboard ready...")
      if (!!searchParams.subdomain && !subdomainCheck) {
        if (isAllowedHost) {
          setTimeout(
            function() {
              // window.location.href = `https://${searchParams.src}.${process.env.PRODUCT_URL}`
              window.location.href = `https://${searchParams.src}.${searchParams.host}/accounts-web/accounts/loadaccountdetails?CustomerSubDomain=${searchParams.subdomain}&token=${encodedToken}`
            },
            searchParams.src === "euserv" ? 1000 : 5000
          )
        } else {
          window.location.pathname = `404`
        }
      } else window.location.href = `${process.env.SITE_URL}${searchParams.src}`
    } else {
      setMessage(
        "SuperOps is only available as a web app for desktop. To access your account, please click the link we’ve sent to your registered email address."
      )
    }
  }, [searchParams.src])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentCookiesTermsBetaV2 {
            SuperOps {
              pages(where: { title: "Signup - Thank You" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="signup-thank-you">
            {data.SuperOps.pages.map(page => {
              const { navigationBlock, seo } = page
              return (
                <>
                  <Frame seo={seo}>
                    <header>
                      <Navigation links={navigationBlock} page="Thank You" />
                    </header>
                    <img
                      src="https://ct.capterra.com/capterra_tracker.gif?vid=2197571&vkey=850b9a02ec1ce9ecd265236dbe24526f"
                      alt="track"
                    />
                    <Container className="signup-ty v2-signup-thanks">
                      <center>
                        <img src={ThankIcon} height="10" width="10" alt="img" />
                        <p>{message}</p>
                      </center>
                    </Container>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default ThankYou
